<template>
    <div class="archive-menu-icon-select--vue-select--wrapper">
        <div role="button"
             class="clear-button"
             @click="onClear"
             v-if="selected">
            <font-awesome-icon size="lg" icon="times" />
        </div>
        <v-select :id="`input-users-${_uid}`"
                  v-model="selected"
                  label="text"
                  :disabled="disabled"
                  :filterable="true"
                  :clearable="false"
                  :options="menuIcons"
                  @option:selected="onSelect">
            <template slot="no-options">
                Välj ikon
            </template>
            <template slot="option" slot-scope="option">
                <div class="d-center">
                    <div><font-awesome-icon :icon="option.value" size="lg" /></div>
                    <div class="ml-2">{{ option.text }}</div>
                </div>
            </template>
            <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                    <div><font-awesome-icon :icon="option.value" size="lg" /></div>
                    <div class="ml-2">{{ option.text }}</div>
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
    import {
        getAll,
        getByValue
    } from '@/services/archive-icon.service';

    export default {
        name: 'ArchiveMenuIconSelect',
        props: {
            disabled: Boolean,
            value: String
        },
        data() {
            return {
                menuIcons: getAll(),
                selected: null
            };
        },
        watch: {
            value(newValue) {
                if (!newValue)
                    this.selected = null;
                else
                    this.selected = getByValue(newValue);
            }
        },
        methods: {
            onSelect() {
                this.$emit('input', this.selected.value);
            },
            onClear() {
                this.selected = null;
                this.$emit('input', null);
            }
        }
    };
</script>

<style scoped>
    .archive-menu-icon-select--vue-select--wrapper {
        position: relative;
    }

        .archive-menu-icon-select--vue-select--wrapper .clear-button {
            position: absolute;
            right: 33px;
            top: 8px;
            z-index: 99;
            color: rgba(60, 60, 60, 0.5);
        }

    .d-center {
        display: flex;
        align-items: center;
    }

    .v-select .dropdown li {
        border-bottom: 1px solid rgba(112, 128, 144, 0.1);
    }

        .v-select .dropdown li:last-child {
            border-bottom: none;
        }

        .v-select .dropdown li a {
            padding: 10px 20px;
            width: 100%;
            font-size: 1.25em;
            color: #3c3c3c;
        }

    .v-select .dropdown-menu .active > a {
        color: #fff;
    }

    .v-select.vs--single.vs--searchable {
        min-width: 100%;
        background: white;
    }

    .v-select .vs__dropdown-toggle {
        min-height: 38px;
    }

    .vs__selected {
        min-height: 100%;
    }
</style>