'use strict';

const AVAILABLE_ICONS = [{
    value: 'fire',
    text: 'Eld'
}, {
    value: 'fire-extinguisher',
    text: 'Brandsläckare'
}, {
    value: 'sensor-fire',
    text: 'Brandvarnare'
}, {
    value: 'fire-smoke',
    text: 'Eld och rök'
}, {
    value: 'blanket',
    text: 'Brandfilt'
}, {
    value: 'tachometer-alt',
    text: 'Översikt'
}, {
    value: 'home',
    text: 'Hus'
}, {
    value: 'building',
    text: 'Byggnad'
}, {
    value: 'user',
    text: 'Person'
}, {
    value: 'users',
    text: 'Personer'
}, {
    value: 'address-book',
    text: 'Adressbok'
}, {
    value: 'box-open',
    text: 'Box'
}, {
    value: 'folder-open',
    text: 'Öppen mapp'
}, {
    value: 'folder',
    text: 'Stängd mapp'
}, {
    value: 'folders',
    text: 'Mappar'
}, {
    value: 'sitemap',
    text: 'Sidkarta'
}, {
    value: 'file',
    text: 'Fil'
}, {
    value: 'paperclip',
    text: 'Gem'
}, {
    value: 'link',
    text: 'Länk'
}, {
    value: 'external-link',
    text: 'Extern länk'
}, {
    value: 'clipboard-check',
    text: 'Anteckningsblock OK-bock'
}, {
    value: 'clipboard-list',
    text: 'Anteckningsblock lista'
}, {
    value: 'check-square',
    text: 'OK-bock'
}, {
    value: 'info-circle',
    text: 'Information'
},
{
    value: 'exclamation-triangle',
    text: 'Utropstecken'
}, {
    value: 'cog',
    text: 'Kuggjhul'
}];

export function getAll() {
    return AVAILABLE_ICONS;
};

export function getByValue(value) {
    return AVAILABLE_ICONS.find(x => x.value === value);
};