<template>
    <div>
        <b-form style="margin-bottom: 9px" @submit.prevent="onSubmit">
            <!-- Name -->
            <b-form-group id="input-group-name" label="Namn" label-for="input-name">
                <b-form-input id="input-name"
                              v-model="form.name"
                              type="text"
                              placeholder="Ange namn"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-name-help-block"
                              required />
                <b-form-text id="input-name-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Name')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-sortOrder"
                          label="Sorteringordning"
                          label-for="input-sortOrder">
                <b-form-input id="input-sortOrder"
                              v-model="form.sortOrder"
                              type="text"
                              placeholder="Ange sorteringordning"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-sortOrder-help-block"
                              required />
                <b-form-text id="input-sortOrder-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('SortOrder')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <!-- Select type -->
            <b-form-group id="input-group-type" label="Typ" label-for="input-type">
                <b-form-select id="input-type"
                               v-model="form.type"
                               :options="allTypes"
                               :disabled="isSubmitting"
                               aria-describedby="input-type-help-block"
                               required />
                <b-form-text id="input-type-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Type')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <!-- If icon -->
            <b-form-group id="input-group-menuIcon"
                          label="Typ"
                          label-for="input-menuIcon"
                          v-if="form.type !== 2">
                <ArchiveMenuIconSelect v-model="form.menuIcon" />
                <b-form-text id="input-type-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('MenuIcon')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <!-- Files -->
            <FileUploader ref="fileUploader"
                          :entity-type="'archive' | getEntityTypeIdFromText"
                          :entity-id="form.id" />
            <!-- Files -->
            <!-- Building -->
            <div>
                <b-form-group id="input-group-buildingId"
                              label="Fastighet"
                              style="margin-top: 11px"
                              label-for="input-buildingId">
                    <b-form-select id="input-buildingId"
                                   v-model="selectedBuilding"
                                   :options="allBuildings"
                                   :disabled="isSubmitting"
                                   value-field="value"
                                   text-field="text"
                                   aria-describedby="input-buildingId-help-block" />
                    <b-form-text id="input-parentId-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('BuildingId')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>
            </div>
            <!-- Parent -->
            <div v-if="form.type !== 2">
                <b-form-group id="input-group-parentId"
                              label="Förälder"
                              style="margin-top: 11px"
                              label-for="input-parentId">
                    <b-form-select id="input-parentId"
                                   v-model="form.parentId"
                                   :options="availableParents"
                                   :disabled="isSubmitting"
                                   value-field="id"
                                   text-field="title"
                                   aria-describedby="input-parentId-help-block" />
                    <b-form-text id="input-parentId-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('ParentId')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>
            </div>

            <!-- Is Link -->
            <div v-if="isLink">
                <b-form-group id="input-group-url" label="Länk" label-for="input-url">
                    <b-form-input id="input-url"
                                  v-model="form.url"
                                  type="text"
                                  placeholder="Ange länk"
                                  :disabled="isSubmitting"
                                  autocomplete="off"
                                  aria-describedby="input-url-help-block"
                                  :required="isLink" />
                    <b-form-text id="input-url-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Url')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>
            </div>

            <!-- Is Global Link -->
            <div v-if="form.type === 6">
                <b-form-group id="input-group-url" label="Länk" label-for="input-url">

                    <b-form-select id="input-buildingId"
                                   v-model="form.url"
                                   :options="globalLinks"
                                   :disabled="isSubmitting"
                                   value-field="url"
                                   text-field="name"
                                   aria-describedby="input-url-help-block" />               
                    <b-form-text id="input-url-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Url')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>
            </div>

            <!-- Is Editor -->
            <div v-if="form.type === 0">
                <b-form-group id="input-group-editor"
                              label="Innehåll"
                              label-for="input-editor">
                    <Editor id="input-editor"
                            v-model="form.content"
                            aria-describedby="input-editor-help-block" />
                    <b-form-text id="input-editor-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Content')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>
            </div>
            <!-- ./ Is Editor -->

            <b-alert :show="validationByKey('Other').length > 0" variant="danger">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Other')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-alert>

            <b-alert :show="successMessage.length > 0"
                     variant="success"
                     class="mb-0 mt-4">
                <h6 class="mb-0 text-center">
                    <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                </h6>
            </b-alert>

            <b-button v-b-tooltip.hover
                      variant="primary"
                      type="submit"
                      block
                      class="mt-4"
                      :disabled="isSubmitting"
                      :title="isSubmitting ? 'Sparar...' : ''">
                <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
            </b-button>

            <b-button v-b-tooltip.hover
                      variant="danger"
                      block
                      class="mt-2"
                      :disabled="isSubmitting"
                      :title="isSubmitting ? 'Tar bort...' : ''"
                      @click="remove">
                <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Ta bort
            </b-button>
        </b-form>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Editor from '@/components/Editor';
    import FileUploader from '@/components/file/FileUploader';
    import ArchiveMenuIconSelect from '@/components/archive/ArchiveMenuIconSelect';
    import { post, get, remove } from '@/helpers/api';

    export default {
        name: 'SaveArchive',
        components: {
            Editor,
            FileUploader,
            ArchiveMenuIconSelect
        },
        props: {
            id: {
                type: Number,
                default: 0
            },
            parentId: {
                type: Number,
                default: null
            },
            buildingId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                form: {
                    id: 0,
                    type: 3,
                    name: '',
                    parentId: null,
                    url: null,
                    sortOrder: 0,
                },
                allBuildings: [
                    { value: null, text: '-- Ingen --' },
                ],
                selectedBuilding: this.$props.buildingId,
                globalLinks: []
            };
        }, 
        computed: {
            ...mapState({
                allTypes: (state) => state.archive.types,
                archive: (state) => state.archive.menu,
                selectedCustomerId: (state) => state.user.selectedCustomer.id
            }),
            isEditMode() {
                return this.form.id > 0;
            },
            isLink() {
                return this.form.type === 4 || this.form.type === 5;
            },
            availableParents() {
                let available = [{ id: null, title: '---Ingen---' }];

                for (var i = 0; i < this.archive.length; i++) {
                    const parent = this.archive[i];

                    if (this.buildingId && this.buildingId !== parent.buildingId) {
                        continue;
                    }

                    // Ignore headers.
                    if (parent.type === 2) continue;
                    // Ignore external links.
                    if (parent.type === 5) continue;

                    available.push({
                        id: parent.id,
                        html: parent.title,
                        disabled: parent.id === this.form.id
                    });

                    for (var c = 0; c < parent.child.length; c++) {
                        const child = parent.child[c];
                        // Ignore external links.
                        if (child.type === 5) continue;
                        available.push({
                            id: child.id,
                            html: `&nbsp;&#8627;&nbsp;${child.title}`,
                            disabled: child.id === this.form.id
                        });

                        //for (var sc = 0; sc < child.child.length; sc++) {
                        //    const subChild = child.child[sc];
                        // Ignore external links.
                        //if (parent.type === 5)
                        //    continue;
                        //    available.push({
                        //        id: subChild.id,
                        //        html: `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${subChild.title}`,
                        //        disabled: subChild.id === this.form.id
                        //    });
                        //}
                    }
                }

                return available;
            }
        },
        async mounted() {
            await this.getAllBuildings();
            this.getAllGlobalLinks();

            // Get archive if necessary.
            this.form.id = this.id;

            if (this.parentId) {
                this.form.parentId = this.parentId; // TODO:!
            }

            if (this.isEditMode) {
                await get('Archive', `Get?id=${this.form.id}`)
                    .then((x) => {
                        this.form = x.data;
                    })
                    .catch((x) => {
                        alert(x.data);
                    });
            }
        },
        methods: {
            async getAllBuildings() {
                await get('Building', `GetAll/${this.selectedCustomerId}`)
                    .then((x) => {
                        this.allBuildings = [
                            ...this.allBuildings,
                            ...x.data.map(x => ({
                                value: x.id,
                                text: x.name
                            }))
                        ];
                    })
                    .catch((x) => {
                        alert('Något gick snett vid hämtning av fastigheter.');
                    });
            },
            async getAllGlobalLinks() {
                await get('Archive', 'GetAllByGlobalCustomer')
                    .then((x) => {
                        this.globalLinks = x.data;
                    })
                    .catch((x) => {
                        alert('Något gick snett vid hämtning av fastigheter.');
                    });
            },
            reset() {
                this.successMessage = '';
                this.validationErrors = null;
            },
            async onSubmit() {
                this.isSubmitting = true;
                this.reset();

                // Post.
                await post('Archive', 'Save', {
                    ...this.form,
                    customerId: this.selectedCustomerId,
                    buildingId: this.selectedBuilding,
                })
                    .then((x) => {
                        this.isSubmitting = false;
                        this.form.sortOrder = !isNaN(this.form.sortOrder)
                            ? parseInt(this.form.sortOrder)
                            : 9999;
                        this.form.id = parseInt(x.data.entityId);
                        // Save files then emit 'saved'.
                        this.$refs.fileUploader
                            .save(this.form.id)
                            .then((x) =>
                                this.$emit('event', { action: 'saved', item: this.form })
                            );
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                        this.isSubmitting = false;
                    });
            },
            remove() {
                // Confirm.
                this.$bvModal
                    .msgBoxConfirm('Innehållet kommer att raderas', {
                        title: 'Är du säker?',
                        size: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed) {
                            this.reset();

                            // Deletes.
                            remove('Archive', `Delete/${this.form.id}`)
                                .then((x) => {
                                    this.isSubmitting = false;
                                    this.$emit('event', { action: 'deleted', item: this.form });
                                })
                                .catch((x) => {
                                    this.validationErrors = x.response.data.errors;
                                    this.isSubmitting = false;
                                });
                        }
                    })
                    .catch(() => {
                        // An error occurred
                        this.validationErrors = [{ Other: 'Något gick snett' }];
                        this.isSubmitting = true;
                    });
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            }
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
